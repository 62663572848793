<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>高校</el-breadcrumb-item>
                <el-breadcrumb-item>高校新闻</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="serarch  flex align-center justify-between">
                <div class="searchleft">
                    <!-- <el-form :inline="true" :model="searchform" class="demo-form-inline">
                        <el-form-item label="标题">
                            <el-input v-model="searchform.workName"  placeholder="新闻标题"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" icon="el-icon-search"  @click="onSubmit"  >查询</el-button>
                        </el-form-item>
                    </el-form> -->
                </div>
                <div class="searchright">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" @click="openAdd">添加新闻</el-button>
                </div>
            </div>

            <div class="list">
                <el-table
                :data="list"
                border
                :header-cell-style="{'font-weight':'bold','color':'#555555','padding':'6px 0'}"
                stripe
                style="width: 100%">
                    <el-table-column
                    prop="title"
                    label="标题">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="60"
                    label="图片">
                        <template slot-scope="scope">
                            <show-img  :url="scope.row.url" width="300" wid="40px"></show-img>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="pageviews"
                    align="center"
                    label="点击数">
                    </el-table-column>
                    <el-table-column
                    prop="author"
                    label="发布人">
                    </el-table-column>
                    <el-table-column
                    prop="creationTime"
                    label="发布时间">
                    </el-table-column>
                    <el-table-column
                    prop="contacts"
                    width="180"
                    label="操作">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="gotoEdit(scope.row)">编辑</el-button>
                            <el-button type="danger"  @click="delArticle(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <app-page :total="total" :current="pageNo" :pageSize="pageSize" @handleCurrentChange="currentchange" @handleSizeChange="handchange"></app-page>
        </div>
        <Addnews :show.sync="show" :selectobj="selectobj" @getList="getList"></Addnews>
    </div>
</template>
<script>
import apiurl from '@/api/information'
import _api from '@/api/index'
import Addnews from './addNews.vue'
export default{
    components:{
        Addnews,
    },
    data(){
        return{
            show:false,
            selectobj:null,
            searchform:{
                type:localStorage.getItem("schoolId")*1
            },
            list:[],
            total:0,
            pageSize:10,
            pageNo:1,
        }
    },
    created(){
        this.pageNo=this.getContextData("currentPageinfo") || 1;
        this.getList()
    },
    methods:{
        gotoEdit(row){
            this.selectobj=row
            this.show=true
        },
        delArticle(row){
            _api.get(apiurl.newsdel,{id:row.id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }
            })
        },
        onSubmit(){

        },
        openAdd(){
            this.selectobj=null
            this.show=true
        },
        getList(){
            _api.post(apiurl.newslist,{pageNo:this.pageNo,pageSize:this.pageSize,...this.searchform}).then(res=>{
                //console.log(res)
                if(res.success){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        },
        getContextData: function(key){
            const str = sessionStorage.getItem(key);
            if( typeof str == "string" ){
                try{
                    return JSON.parse(str);
                }catch(e) {
                    return str;
                }
            }
            return;
        },
        handchange(data){//分页Size变化
            this.pageSize=data
            this.pageNo=1
            this.getList()
        },
        currentchange(data){//当前页变化
            console.log(data)
            this.pageNo=data
            this.getList()
        },
    }
}
</script>


