<template>
    <el-dialog
    :title="status==1?'添加新闻':'编辑新闻'"
    :visible.sync="show"
    width="60%"
    @open="open"
    @opened="opened"
    :before-close="handleClose">
    <div>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="文章名称">
                <el-input v-model="form.title" style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item label="分类">
                <el-select v-model="form.classId" placeholder="请选择分类">
                <el-option v-for="classitem in classlist" :label="classitem.className" :value="classitem.id"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="地区" prop="holdField" v-show="userInfo.orgId==0">
                <el-select v-model="form.orgId" placeholder="请选地区">
                    <el-option
                    v-for="item in orglist"
                    :key="item.orgId"
                    :label="item.districtName"
                    :value="item.orgId">
                    </el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="来源">
                    <el-input v-model="form.source" style="width:400px;"></el-input>
            </el-form-item>
            <el-form-item label="封面图">
                <upload-img :modeldata="form" @changeurl="" style="margin-top:15px;"></upload-img>
            </el-form-item>
            <el-form-item label="内容">
                <div class="editor-container">
                <Uediter style="overflow:auto;height:650px;" v-if="allload"  :defaultMsg="defaultMsg" :config="config" ref="ue"></Uediter>
                </div>
            </el-form-item>

        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addArticle">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import Uediter from '@/components/ue.vue';
import apiUrl from '@/api/information'
import apiUrl2 from '@/api/public'
import _api from '@/api/index'

export default{
    components: {Uediter},
    props:{
        show:{
            type:Boolean,
            default:false
        },
        selectobj:{
            type:Object,
            default:null
        }
    },
    data(){
        return{
            schoolId:localStorage.getItem("schoolId"),
            defaultMsg: '',
            config: {
                initialFrameWidth: 995,
                initialFrameHeight: 400
            },
            status:1,
            form:{
                title:"",
                id:null,
                classId:"",
                source:"",
                url:"",
                articleContent:"",
                author:"",
                orgId:null,
            },
            classlist:[],
            allload:false,
        }
    },
    methods:{
        getDetail(){
            _api.get(apiUrl.newsdetail,{id:this.selectobj.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.form.title=res.data.title
                    this.form.id=this.selectobj.id
                    this.form.classId=res.data.classId
                    this.form.source=res.data.source
                    this.form.url=res.data.url
                    this.form.orgId=res.data.orgId
                    this.form.articleContent=res.data.articleContent
                    this.form.author=res.data.author
                    
                    setTimeout(()=>{
                        console.log(this.$refs.ue)
                        this.$refs.ue.setUEContent(res.data.articleContent)
                    },300)
                    
                    this.defaultMsg=res.data.articleContent
                    this.allload=true
                }
            })
        },
        open(){
            if(this.selectobj){
                this.status=2
                for(let k in this.form){
                    this.form[k]=this.selectobj[k]
                }
                this.getDetail()
                
            }else{
                this.status=1
                this.allload=true
            }
            this.getClass()
        },
        opened(){
            // console.log(this.$refs.ue)
            // this.$refs.ue.setUEContent(this.selectobj.articleContent)
        },
        addArticle(){
            this.form.articleContent=this.$refs.ue.getUEContent()
            if(this.form.title==""){
                this.$message.error("请输入标题！")
                return
            }
            if(this.form.classId==""){
                this.$message.error("请选择分类！")
                return
            }
            if(this.form.source.length>10){
                this.$message.error("来源长度不能超过10个字符！")
                return
            }
           
            let apiu=this.status==1?apiUrl.newsinsert:apiUrl.newsedit

            _api.post(apiu,this.form).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("操作成功！");
                    this.form.title=""
                    this.form.classId=""
                    this.form.source=""
                    id:null,
                    this.form.url=""
                    this.form.orgId=null
                    this.form.articleContent=""
                    this.$refs.ue.setUEContent('');
                    this.$emit("getList",null)
                    this.handleClose()
                   // Object.keys(this.form).forEach(key=>(this.form[key]=""))
                    
                }
            })
        },
        handleClose(){
            this.form={
                title:"",
                classId:"",
                source:"",
                url:"",
                articleContent:"",
                author:"",
                orgId:null,
            }
            this.$refs.ue.setUEContent('')
            this.$emit("update:show",false)
        },
        getClass(){
            _api.get(apiUrl2.classificationlist,{type:this.schoolId}).then(res=>{
                if(res.code==200){
                    this.classlist=res.data
                }
            })
        }
    }
}
</script>


